import React from "react";
import { useState } from "react";
import "./style.css";
import EmailBox from "./EmailBox";
import Samp from "./Samp";
import ArrowBox from "./ArrowBox";
import Pobox from "./Pobox";

import Header from "./Header";

import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";

import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import UserListService from "../../services/user-list.service";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import Pdf from "./Pdf";
import { Icon } from "@iconify/react";
import hotel from "../../Images/hotel.jpg";
import bedRoom from "../../Images/bed-room.jpg";

function RegisterForm(props) {
  const navigate = useNavigate();

  const [fields, setFields] = useState({ voucher: "" });
  const [errors, setErrors] = useState({});

  const [i_agree, setI_agree] = useState(false);

  const [captcha, setCaptcha] = useState(false);
  const [showPage, setShowPage] = useState(false);

  const [checkvoucherfield, setCheckvoucherfield] = useState(
    "The voucher number is required."
  );

  useEffect(() => {
    if (localStorage.getItem("returnpage") == "true") {
      navigate("/user-form");
    } else {
      setShowPage(true);
      localStorage.setItem("returnpage", false);

      const keysToKeep = ["@secure.s.acessTokens", "returnpage", "theme"]; // Specify the keys you want to keep
      function clearLocalStorageExcept(keysToKeep) {
        for (let key in localStorage) {
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }

      clearLocalStorageExcept(keysToKeep);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields((preVal) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const submituserRegistrationForm = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setFields({ voucher: "" });

      let errors = {};
      const checkCount = 0;

      if (checkCount == 0) {
        if (i_agree) {
          let voucher_two = fields.voucher;

          try {
            const response = await UserListService.registerForm(fields.voucher);

            localStorage.setItem("Survey", response?.data?.response?.survey);
            localStorage.setItem("Logo", response?.data?.response?.logo);

            localStorage.setItem("isVoucher", true);
            const pagename = await response?.data?.response?.redirect;

            if (pagename === "userform") {
              localStorage.setItem("voucher", voucher_two);
              navigate("/user-form");
            }
          } catch (err) {
            if (
              err.response?.status === 404 &&
              err?.response?.data?.response?.redirect === "cert-details"
            ) {
              localStorage.setItem("cert_details", true);
              const detail_data = err?.response?.data?.response;
              secureLocalStorage.setItem(
                "cert_details",
                JSON.stringify({
                  fname: detail_data.fname,
                  lname: detail_data.lname,
                  address: detail_data.address,
                  address2: detail_data.address2,
                  city: detail_data.city,
                  state: detail_data.state,
                  zip: detail_data.zip,
                  phone: detail_data.phone,
                  email: detail_data.email,
                  cardImage: detail_data.prod_image,
                  cardName: detail_data.prod_name,
                })
              );
              navigate("/cert_details");
            } else if (
              err.response?.data?.status === 404 &&
              err?.response?.data?.response?.redirect === "redemption"
            ) {
              errors["voucher"] = err?.response?.data?.response?.message;
              setCheckvoucherfield(err?.response?.data?.response?.message);

              setErrors(errors);
              return false;
            } else {
              errors["voucher"] = err?.response?.data?.response?.message;
              setCheckvoucherfield(err?.response?.data?.response?.message);

              setErrors(errors);
              return false;
            }
          }
        }
      }
    }
  };
  const validateForm = () => {
    // let fields = fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["voucher"]) {
      formIsValid = false;
      errors["voucher"] = checkvoucherfield;

      setErrors(errors);
      return false;
    }

    if (!i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";

      setErrors(errors);
      return false;
    }
    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "captcha not verified";

      setErrors(errors);
      return false;
    }
    return formIsValid;
  };
  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <Header />

      <div className="container-fluid">
        <div id="main-registration-container">
          <div id="register">
            <div className="white-box">
              <h1>PLEASE READ THESE STEPS CAREFULLY</h1>
              <ul className="points">
                <form
                  method="post"
                  name="userRegistrationForm"
                  className="form-outer"
                  onSubmit={submituserRegistrationForm}
                >
                  <li className="pdf_box">
                    <EmailBox />
                  </li>
                  <li className="pdf_box">
                    <Samp />
                  </li>
                  <li className="pdf_box">
                    <ArrowBox />
                  </li>
                  <li className="pdf_box">
                    <Pobox />
                  </li>
                  <li className="pdf_box">
                    <Pdf />
                  </li>
                  <li key="uniqueId2">
                    <div className="input-outer1">
                      <label>VOUCHER NUMBER</label>
                      <input
                        type="text"
                        maxLength="11"
                        // placeholder="Enter Voucher Number"
                        name="voucher"
                        className="searchBox_deals vc-validate"
                        value={fields.voucher}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">{errors.voucher}</div>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          id="myCheck"
                          name="test"
                          className="conditions"
                          value={fields.checkbox}
                          onChange={handleChangecheckbox}
                        />
                        <span> YES, I HAVE READ THE ABOVE INFORMATION</span>
                        <div className="errorMsg">{errors.checkederror}</div>
                      </label>
                      <br></br>
                      <div className="recaptacha-design">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                          onChange={onCaptchaChange}
                        />
                      </div>
                      <div className="errorMsg">
                        {errors.checkederrorcaptcha}
                      </div>
                      <input
                        type="submit"
                        name="submit"
                        id="submit"
                        value="SUBMIT"
                        className="sub-btn button"
                      ></input>
                    </div>
                  </li>

                  <li className="text-center">
                    <span className="bottom text-center">
                      Once you register your voucher number your travel request
                      forms will be mailed to you
                    </span>
                  </li>
                </form>
              </ul>
              <div className="footer-img">
                <div className="row">
                  <div className="col-md-6">
                    <img src={bedRoom} className="footer-image" />
                  </div>

                  <div className="col-md-6">
                    <img src={hotel} className="footer-image" />
                  </div>

                  <div className="footer-info">
                    <ul className="info-list clearfix">
                      <li>
                        <a href="tel:800-585-9806">
                          <span className="phone-icon">
                            <Icon icon="fluent:call-48-filled" />
                          </span>
                          800-585-9806
                        </a>
                      </li>
                      <li>
                        <a href="mailto:customercare@eliterewards.biz">
                          <Icon icon="clarity:email-solid" />
                          customercare@eliterewards.biz
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
