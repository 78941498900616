import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";

import Pagination from "react-js-pagination";

import formatThousands from "format-thousands";
import { useMemo } from "react";

export default function UserUploadsView() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");

  const [filteredData, setFilteredData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const { id } = useParams();

  const filesLists = async () => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getFilesUploadedView(id);
      let res;

      if (Array.isArray(responce?.data?.response?.response)) {
        res = responce?.data?.response?.response;
      } else if (typeof responce?.data?.response?.response === "object") {
        res = Object.values(responce?.data?.response?.response);
      }

      const results = [];
      res.map((value) => {
        return results.push({
          address: value.address,
          address1: value.address2,
          amount: value.amount,
          cert_no: value.cert_no,
          cert_status: value.cert_status,
          cert_type: value.cert_type,
          city: value.city,
          company_name: value.company_name,
          company_no: value.company_no,
          concatenated: value.concatenated,
          customer_id: value.customer_id,
          customer_order: value.customer_order,
          denomination: value.denomination,
          email: value.email,
          fname: value.fname,
          fulfilled: value.fulfilled,
          invoice_no: value.invoice_no,
          job_no: value.job_no,
          lname: value.lname,
          phone_no: value.phone_no,
          received: value.received,
          shipped: value.shipped,
          state: value.state,
          status: value.status,
          voucher_no: value.voucher_no,
          zip: value.zip,
          created: value.created,
          expiration: value.expiration,
          exported: value.exported,
          issuance: value.issuance,
          trips:
            value.trip1 +
            " " +
            value.trip2 +
            " " +
            value.trip3 +
            " " +
            value.trip4 +
            " " +
            value.trip5 +
            " " +
            value.trip6 +
            " " +
            value.trip7 +
            " " +
            value.trip8 +
            " " +
            value.trip9 +
            " " +
            value.trip10 +
            " " +
            value.trip11 +
            " " +
            value.trip12 +
            " " +
            value.trip13 +
            " " +
            value.trip14 +
            " " +
            value.trip15 +
            " " +
            value.trip16 +
            " " +
            value.trip17 +
            " " +
            value.trip18 +
            " " +
            value.trip19 +
            " " +
            value.trip20,
        });
      });

      settableData([...results]);
      setFilteredData([...results]);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      filesLists();
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  // table function for custom searching and limit

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((value) => {
        // Customize this condition based on your data structure and search requirements
        return (
          value.address.toString().toLowerCase().includes(searchQuery) ||
          value.address1.toString().toLowerCase().includes(searchQuery) ||
          value.amount.toString().toLowerCase().includes(searchQuery) ||
          value.cert_no.toString().toLowerCase().includes(searchQuery) ||
          value.cert_status.toString().toLowerCase().includes(searchQuery) ||
          value.cert_type.toString().toLowerCase().includes(searchQuery) ||
          value.city.toString().toLowerCase().includes(searchQuery) ||
          value.company_name.toString().toLowerCase().includes(searchQuery) ||
          value.company_no.toString().toLowerCase().includes(searchQuery) ||
          value.concatenated.toString().toLowerCase().includes(searchQuery) ||
          value.customer_id.toString().toLowerCase().includes(searchQuery) ||
          value.customer_order.toString().toLowerCase().includes(searchQuery) ||
          value.denomination.toString().toLowerCase().includes(searchQuery) ||
          value.email.toString().toLowerCase().includes(searchQuery) ||
          value.fname.toString().toLowerCase().includes(searchQuery) ||
          value.fulfilled.toString().toLowerCase().includes(searchQuery) ||
          value.invoice_no.toString().toLowerCase().includes(searchQuery) ||
          value.job_no.toString().toLowerCase().includes(searchQuery) ||
          value.lname.toString().toLowerCase().includes(searchQuery) ||
          value.phone_no.toString().toLowerCase().includes(searchQuery) ||
          value.received.toString().toLowerCase().includes(searchQuery) ||
          value.shipped.toString().toLowerCase().includes(searchQuery) ||
          value.state.toString().toLowerCase().includes(searchQuery) ||
          value.status.toString().toLowerCase().includes(searchQuery) ||
          value.voucher_no.toString().toLowerCase().includes(searchQuery) ||
          value.zip.toString().toLowerCase().includes(searchQuery) ||
          value.created.toString().toLowerCase().includes(searchQuery) ||
          value.expiration.toString().toLowerCase().includes(searchQuery) ||
          value.exported.toString().toLowerCase().includes(searchQuery) ||
          value.issuance.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const calculateRange = (data, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(data.length / rowsPerPage);

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    return range;
  };

  const sliceData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  const useTable = (data, page, rowsPerPage) => {
    const [tableRange, setTableRange] = useState([]);
    const [slice, setSlice] = useState([]);

    useMemo(() => {
      // console.log("E1");

      const range = calculateRange(data, rowsPerPage);
      setTableRange([...range]);

      const slice = sliceData(data, page, rowsPerPage);
      setSlice([...slice]);
    }, [data, setTableRange, page, setSlice, rowsPerPage]);

    return { slice, range: tableRange };
  };

  const Table = ({ data, rowPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowPerPage);
    return (
      <div className="table-responsive">
        <table className="text-nowrap  table">
          <thead className="table-light">
            <tr>
              <th scope="col" width="50px">
                Job #
              </th>
              <th scope="col" width="50px">
                Voucher #
              </th>
              <th scope="col" width="250px">
                Company Info
              </th>
              <th scope="col" width="250px">
                Certificate Info
              </th>
              <th scope="col" width="50px">
                Status
              </th>
              <th scope="col" width="50px">
                Exported
              </th>
              <th scope="col" width="300px">
                Customer Info
              </th>

              <th scope="col" width="50px">
                Denomination
              </th>
              <th scope="col" width="50px">
                Invoice #
              </th>
              <th scope="col" width="200px">
                Trip
              </th>
            </tr>
          </thead>
          <tbody>
            {slice.length ? (
              slice.map((el, index) => (
                <tr key={index}>
                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.job_no}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.voucher_no}
                    </span>
                  </td>
                  <td className="align-middle">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Company Name:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.company_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Company Number:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.company_no}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="align-middle">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Certificate Number:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.cert_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Certificate Status:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.cert_status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Certificate Type:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.cert_type}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Concatenated:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.concatenated}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Amount:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.amount}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Shipped:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.shipped}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Received:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.received}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Fulfilled:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.fulfilled}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Created On:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.created}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Begin Date:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.issuance}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Expiration Date:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.expiration}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="align-middle">
                    <span
                      className={`text-control font-17 text-capitalize ${
                        el.status === "active" || el.status === "Valid"
                          ? "text-success"
                          : el.status === "used"
                          ? "text-primary"
                          : el.status === "expired" || el.status === "archive"
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {el.status !== "" ? el.status : "N/A"}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.exported}
                    </span>
                  </td>
                  <td className="align-middle">
                    <table className="inner-table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Customer Id:</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.customer_id}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Customer (Order):</strong>
                          </td>
                          <td>
                            <span className="text-control">
                              {el.customer_order}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>First Name:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.fname}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Last Name:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.lname}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.address}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address2:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.address1}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>City:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.city}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>State:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.state}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Zip:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.zip}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Phone:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.phone_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email:</strong>
                          </td>
                          <td>
                            <span className="text-control">{el.email}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.denomination}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.invoice_no}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="text-control text-uppercase">
                      {el.trips}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={rowPerPage}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Uploaded Users Files" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">
                    Total Records:{" "}
                    <span className="text-primary-color fw-bold">
                      {formatThousands(tableData?.length, {
                        separator: ",",
                      })}
                    </span>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleSearch}
                            placeholder="Search"
                          />

                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ms-auto">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={filteredData} rowPerPage={limit} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
