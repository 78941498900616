import React from "react";
import img from "../../Images/pdf-icon.png";
import file from "../../Images/Things-to-Know.pdf";

const EmailBox = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <a href={file} target="_blank" rel="noreferrer">
          <img src={img} alt="icon" />
          <p>Things To Know</p>
        </a>
      </div>
    </div>
  );
};
export default EmailBox;
