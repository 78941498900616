import React from "react";
import Logo from "../../Images/logo.webp";

const Header = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="logo">
              <div className="img-outer">
                <img src={Logo} className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
