import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateUser } from "../../../schema";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ImageLoader from "../includes/ImageLoader";
import $ from "jquery";
import Swal from "sweetalert2";

export default function EditUser() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);

  const [errorsServer, setErrorsServer] = useState([]);
  const [packageDescription, setPackageDescription] = useState([]);
  const [notes, setNotes] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  const getSingleUser = async () => {
    setLoading(true);
    try {
      const response = await AdminListService.getOrdersById(id);

      setValues({
        email: response?.data?.response?.email,
        pkgDiscription: response?.data?.response?.package_description,
        phone: response?.data?.response?.phone,
        TrCitizen: response?.data?.response?.traveller_citizenship,
        TRFirst: response?.data?.response?.traveller_fname,
        TrLast: response?.data?.response?.traveller_lname,
        TrUpsells: response?.data?.response?.traveller_upsells,
        fulfilCost: response?.data?.response?.fullfilled_cost,
        destination: response?.data?.response?.destination,
        certiCost: response?.data?.response?.certificate_cost,
        agent: response?.data?.response?.booking_agent,
        altDestination: response?.data?.response?.alternative_destination,
        TrDob:
          response?.data?.response?.traveller_dob !== "" &&
          response?.data?.response?.traveller_dob !== " " &&
          response?.data?.response?.traveller_dob !== "0000-00-00" &&
          moment(response?.data?.response?.traveller_dob).format("YYYY-MM-DD"),
        First: response?.data?.response?.fname,
        address: response?.data?.response?.address1,
        State: response?.data?.response?.state,
        issuance:
          response?.data?.response?.issuance !== "" &&
          response?.data?.response?.issuance !== " " &&
          response?.data?.response?.issuance !== "0000-00-00" &&
          moment(response?.data?.response?.issuance).format("YYYY-MM-DD"),
        CertiStatus: response?.data?.response?.certificate_status,
        Last: response?.data?.response?.lname,
        City: response?.data?.response?.city,
        Zip: response?.data?.response?.zip,
        Expiration:
          response?.data?.response?.expiration !== "" &&
          response?.data?.response?.expiration !== " " &&
          response?.data?.response?.expiration !== "0000-00-00" &&
          moment(response?.data?.response?.expiration).format("YYYY-MM-DD"),

        alternativeDate:
          response?.data?.response?.alternative_date !== "" &&
          response?.data?.response?.alternative_date !== " " &&
          response?.data?.response?.alternative_date !== "0000-00-00" &&
          moment(response?.data?.response?.alternative_date).format(
            "YYYY-MM-DD"
          ),
        requestedDate:
          response?.data?.response?.requested_date !== "" &&
          response?.data?.response?.requested_date !== " " &&
          response?.data?.response?.requested_date !== "0000-00-00" &&
          moment(response?.data?.response?.requested_date).format("YYYY-MM-DD"),
        postmarkDate:
          response?.data?.response?.postmark_date !== "" &&
          response?.data?.response?.postmark_date !== " " &&
          response?.data?.response?.postmark_date !== "0000-00-00" &&
          moment(response?.data?.response?.postmark_date).format("YYYY-MM-DD"),
      });
      setPackageDescription(response?.data?.response?.choices);
      setNotes(response?.data?.response?.notes);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      getSingleUser();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateUserFun = async (values) => {
    setFormSubmiting(true);
    try {
      const response = await AdminListService.updateUserById(
        values,
        admin_id,
        id
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setFormSubmiting(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setFormSubmiting(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let errorData = {};

        errorData["CertiStatus"] =
          err.response?.data &&
          err.response?.data?.certificate_status &&
          err.response?.data?.certificate_status[0];
        errorData["postmarkDate"] =
          err.response?.data &&
          err.response?.data?.postmark_date &&
          err.response?.data?.postmark_date[0];
        errorData["destination"] =
          err.response?.data &&
          err.response?.data?.destination &&
          err.response?.data?.destination[0];
        errorData["Expiration"] =
          err.response?.data &&
          err.response?.data?.expiration &&
          err.response?.data?.expiration[0];
        errorData["fulfilCost"] =
          err.response?.data &&
          err.response?.data?.fullfilled_cost &&
          err.response?.data?.fullfilled_cost[0];
        errorData["agent"] =
          err.response?.data &&
          err.response?.data?.booking_agent &&
          err.response?.data?.booking_agent[0];
        errorData["pkgDiscription"] =
          err.response?.data &&
          err.response?.data?.package_description &&
          err.response?.data?.package_description[0];
        errorData["phone"] =
          err.response?.data &&
          err.response?.data?.phone &&
          err.response?.data?.phone[0];
        errorData["certiCost"] =
          err.response?.data &&
          err.response?.data?.certificate_cost &&
          err.response?.data?.certificate_cost[0];

        errorData["First"] =
          err.response?.data &&
          err.response?.data?.fname &&
          err.response?.data?.fname[0];
        errorData["Last"] =
          err.response?.data &&
          err.response?.data?.lname &&
          err.response?.data?.lname[0];
        errorData["address"] =
          err.response?.data &&
          err.response?.data?.address &&
          err.response?.data?.address[0];

        errorData["City"] =
          err.response?.data &&
          err.response?.data?.city &&
          err.response?.data?.city[0];

        errorData["State"] =
          err.response?.data &&
          err.response?.data?.state &&
          err.response?.data?.state[0];

        errorData["issuance"] =
          err.response?.data &&
          err.response?.data?.issuance &&
          err.response?.data?.issuance[0];

        errorData["phone"] =
          err.response?.data &&
          err.response?.data?.phone &&
          err.response?.data?.phone[0];

        errorData["Zip"] =
          err.response?.data &&
          err.response?.data?.zip &&
          err.response?.data?.zip[0];

        errorData["email"] =
          err.response?.data &&
          err.response?.data?.email &&
          err.response?.data?.email[0];

        errorData["altDestination"] =
          err.response?.data &&
          err.response?.data?.alternative_destination &&
          err.response?.data?.alternative_destination[0];
        errorData["requestedDate"] =
          err.response?.data &&
          err.response?.data?.requested_date &&
          err.response?.data?.requested_date[0];
        errorData["alternativeDate"] =
          err.response?.data &&
          err.response?.data?.alternative_date &&
          err.response?.data?.alternative_date[0];

        errorData["TRFirst"] =
          err.response?.data &&
          err.response?.data?.traveller_fname &&
          err.response?.data?.traveller_fname[0];
        errorData["TrLast"] =
          err.response?.data &&
          err.response?.data?.traveller_lname &&
          err.response?.data?.traveller_lname[0];
        errorData["TrDob"] =
          err.response?.data &&
          err.response?.data?.traveller_dob &&
          err.response?.data?.traveller_dob[0];
        errorData["TrCitizen"] =
          err.response?.data &&
          err.response?.data?.traveller_citizenship &&
          err.response?.data?.traveller_citizenship[0];
        errorData["TrUpsells"] =
          err.response?.data &&
          err.response?.data?.traveller_upsells &&
          err.response?.data?.traveller_upsells[0];

        setErrorsServer(errorData);
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        );
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const delUser = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await AdminListService.deletSingleNote(
            delId,
            admin_id
          );

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1000,
          });
          getSingleUser();
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      First: "",
      address: "",
      State: "",
      issuance: "",
      Expiration: "",
      CertiStatus: "",
      Last: "",
      City: "",
      Zip: "",
      email: "",
      phone: "",
      certiCost: "",
      postmarkDate: "",
      agent: "",
      fulfilCost: "",
      pkgDiscription: "",
      destination: "",
      altDestination: "",
      requestedDate: "",
      alternativeDate: "",
      TRFirst: "",
      TrLast: "",
      TrDob: "",
      TrCitizen: "",
      TrUpsells: "",
      addNotes: "",
    },
    validationSchema: updateUser,
    onSubmit: (values) => {
      updateUserFun(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Edit User" />

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Edit User</h4>
                  <font color="#F00"> All * fields are required. </font>
                </div>
                <div className="card-body">
                  {loading ? (
                    <Skeleton
                      count={20}
                      height="58px"
                      width="48.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="First"
                            value={values.First || ""}
                          />
                          {errors.First && touched.First ? (
                            <span className="text-danger">{errors.First}</span>
                          ) : errorsServer.First ? (
                            <span className="text-danger">
                              {errorsServer.First}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name  "
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Last"
                            value={values.Last || ""}
                          />
                          {errors.Last && touched.Last ? (
                            <span className="text-danger">{errors.Last}</span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="address"
                            value={values.address || ""}
                          />
                          {errors.address && touched.address ? (
                            <span className="text-danger">
                              {errors.address}
                            </span>
                          ) : errorsServer.address ? (
                            <span className="text-danger">
                              {errorsServer.address}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email || ""}
                          />
                          {errors.email && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                          ) : errorsServer.email ? (
                            <span className="text-danger">
                              {errorsServer.email}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="City"
                            value={values.City || ""}
                          />
                          {errors.City && touched.City ? (
                            <span className="text-danger">{errors.City}</span>
                          ) : errorsServer.City ? (
                            <span className="text-danger">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="State"
                            value={values.State || ""}
                          />
                          {errors.State && touched.State ? (
                            <span className="text-danger">{errors.State}</span>
                          ) : errorsServer.State ? (
                            <span className="text-danger">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Zip <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Zip"
                            value={values.Zip || ""}
                          />
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger">{errors.Zip}</span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Phone</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="phone"
                            value={values.phone || ""}
                          />
                          {errors.phone && touched.phone ? (
                            <span className="text-danger">{errors.phone}</span>
                          ) : errorsServer.phone ? (
                            <span className="text-danger">
                              {errorsServer.phone}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Begin Redemption{" "}
                            <span className="text-danger">* (MM/DD/YYYY)</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="issuance"
                            value={values.issuance || ""}
                          />
                          {errors.issuance && touched.issuance ? (
                            <span className="text-danger">
                              {errors.issuance}
                            </span>
                          ) : errorsServer.issuance ? (
                            <span className="text-danger">
                              {errorsServer.issuance}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Expiration{" "}
                            <span className="text-danger">* (MM/DD/YYYY)</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Expiration"
                            value={values.Expiration || ""}
                          />

                          {errors.Expiration && touched.Expiration ? (
                            <span className="text-danger">
                              {errors.Expiration}
                            </span>
                          ) : errorsServer.Expiration ? (
                            <span className="text-danger">
                              {errorsServer.Expiration}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Cert Cost</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Cert Cost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="certiCost"
                            value={values.certiCost || ""}
                          />
                          {errors.certiCost && touched.certiCost ? (
                            <span className="text-danger">
                              {errors.certiCost}
                            </span>
                          ) : errorsServer.certiCost ? (
                            <span className="text-danger">
                              {errorsServer.certiCost}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Postmark Date{" "}
                            <span className="text-danger">(MM/DD/YYYY)</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Postmark Date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="postmarkDate"
                            value={values.postmarkDate || ""}
                          />
                          {errors.postmarkDate && touched.postmarkDate ? (
                            <span className="text-danger">
                              {errors.postmarkDate}
                            </span>
                          ) : errorsServer.postmarkDate ? (
                            <span className="text-danger">
                              {errorsServer.postmarkDate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Booking Agent</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Booking Agent"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="agent"
                            value={values.agent || ""}
                          />
                          {errors.agent && touched.agent ? (
                            <span className="text-danger">{errors.agent}</span>
                          ) : errorsServer.agent ? (
                            <span className="text-danger">
                              {errorsServer.agent}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Fulfilled Cost</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Fulfilled Cost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="fulfilCost"
                            value={values.fulfilCost || ""}
                          />
                          {errors.fulfilCost && touched.fulfilCost ? (
                            <span className="text-danger">
                              {errors.fulfilCost}
                            </span>
                          ) : errorsServer.fulfilCost ? (
                            <span className="text-danger">
                              {errorsServer.fulfilCost}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Certificate Status</label>
                          <select
                            className="form-select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="CertiStatus"
                            value={values.CertiStatus || ""}
                          >
                            <option value="">Please Choose To Change</option>
                            <option value="Cert Sent">Cert Sent</option>
                            <option value="Cert Received">Cert Received</option>
                            <option value="Go Back">Go Back</option>
                            <option value="Booking in Progress">
                              Booking in Progress
                            </option>
                            <option value="Confirmed">Confirmed</option>
                          </select>
                          {errors.CertiStatus && touched.CertiStatus ? (
                            <span className="text-danger">
                              {errors.CertiStatus}
                            </span>
                          ) : errorsServer.CertiStatus ? (
                            <span className="text-danger">
                              {errorsServer.CertiStatus}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Package Description</label>
                          <select
                            className="form-select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="pkgDiscription"
                            value={values.pkgDiscription || ""}
                          >
                            <option value="">Please Choose To Change</option>
                            {packageDescription.length
                              ? packageDescription.map((el, index) => {
                                  return (
                                    <option key={index} value={el}>
                                      {el}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                          {errors.pkgDiscription && touched.pkgDiscription ? (
                            <span className="text-danger">
                              {errors.pkgDiscription}
                            </span>
                          ) : errorsServer.pkgDiscription ? (
                            <span className="text-danger">
                              {errorsServer.pkgDiscription}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Destination</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Destination"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="destination"
                            value={values.destination || ""}
                          />
                          {errors.destination && touched.destination ? (
                            <span className="text-danger">
                              {errors.destination}
                            </span>
                          ) : errorsServer.destination ? (
                            <span className="text-danger">
                              {errorsServer.destination}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Alternative Destination</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Alternative Destination"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="altDestination"
                            value={values.altDestination || ""}
                          />
                          {errors.altDestination && touched.altDestination ? (
                            <span className="text-danger">
                              {errors.altDestination}
                            </span>
                          ) : errorsServer.altDestination ? (
                            <span className="text-danger">
                              {errorsServer.altDestination}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Requested Date</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="requestedDate"
                            value={values.requestedDate || ""}
                          />

                          {errors.requestedDate && touched.requestedDate ? (
                            <span className="text-danger">
                              {errors.requestedDate}
                            </span>
                          ) : errorsServer.requestedDate ? (
                            <span className="text-danger">
                              {errorsServer.requestedDate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Alternative Date</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="alternativeDate"
                            value={values.alternativeDate || ""}
                          />

                          {errors.alternativeDate && touched.alternativeDate ? (
                            <span className="text-danger">
                              {errors.alternativeDate}
                            </span>
                          ) : errorsServer.alternativeDate ? (
                            <span className="text-danger">
                              {errorsServer.alternativeDate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Traveler First Name </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Traveler First Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="TRFirst"
                            value={values.TRFirst || ""}
                          />
                          {errors.TRFirst && touched.TRFirst ? (
                            <span className="text-danger">
                              {errors.TRFirst}
                            </span>
                          ) : errorsServer.TRFirst ? (
                            <span className="text-danger">
                              {errorsServer.TRFirst}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Traveler Last Name </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Traveler Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="TrLast"
                            value={values.TrLast || ""}
                          />
                          {errors.TrLast && touched.TrLast ? (
                            <span className="text-danger">{errors.TrLast}</span>
                          ) : errorsServer.TrLast ? (
                            <span className="text-danger">
                              {errorsServer.TrLast}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Traveler Date Of Birth{" "}
                            <span className="text-danger">(MM/DD/YYYY)</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Traveler Date Of Birth"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="TrDob"
                            value={values.TrDob || ""}
                          />
                          {errors.TrDob && touched.TrDob ? (
                            <span className="text-danger">{errors.TrDob}</span>
                          ) : errorsServer.TrDob ? (
                            <span className="text-danger">
                              {errorsServer.TrDob}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Traveler Citizenship </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Traveler Citizenship:"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="TrCitizen"
                            value={values.TrCitizen || ""}
                          />
                          {errors.TrCitizen && touched.TrCitizen ? (
                            <span className="text-danger">
                              {errors.TrCitizen}
                            </span>
                          ) : errorsServer.TrCitizen ? (
                            <span className="text-danger">
                              {errorsServer.TrCitizen}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Traveler Upsells </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Traveler Upsells:"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="TrUpsells"
                            value={values.TrUpsells || ""}
                          />
                          {errors.TrUpsells && touched.TrUpsells ? (
                            <span className="text-danger">
                              {errors.TrUpsells}
                            </span>
                          ) : errorsServer.TrUpsells ? (
                            <span className="text-danger">
                              {errorsServer.TrUpsells}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Add Notes </label>
                          <textarea
                            className="form-control"
                            placeholder="Add Notes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="addNotes"
                            value={values.addNotes || ""}
                          ></textarea>

                          {errors.addNotes && touched.addNotes ? (
                            <span className="text-danger">
                              {errors.addNotes}
                            </span>
                          ) : errorsServer.addNotes ? (
                            <span className="text-danger">
                              {errorsServer.addNotes}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button className="btn btn-primary my-2 " type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="card dashboard-cards mt-4">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Notes</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Notes</th>
                          <th scope="col">Added Date </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notes.length ? (
                          notes.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">{index + 1}</td>
                              <td className="align-middle">{el.note}</td>
                              <td className="align-middle">
                                {el.created_at !== "" &&
                                  el.created_at !== " " &&
                                  el.created_at !== "0000-00-00" &&
                                  moment(el.created_at).format("YYYY-MM-DD")}
                              </td>
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm btn-icon-text w-adjust"
                                  onClick={() => {
                                    delUser(el.id);
                                  }}
                                >
                                  Delete <i className="bi bi-x-circle"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
